import { ErrorPageQuery } from '.gql/graphql';
import ErrorInformation from '@/components/404/error-information';
import PageBuilder from '@/components/pagebuilder/pageBuilder';
import { withStaticProps } from '@/server/app';
import { serverEnv } from '@/server/env';
import { apolloClient, gql } from '@/utils/apollo';

interface ErrorPageProps {
  cmsPage: ErrorPageQuery['cmsPage'];
}

export default function ErrorPage(props: ErrorPageProps) {
  const { cmsPage } = props;

  if (!cmsPage?.pagebuilder?.html) {
    return <ErrorInformation />;
  }

  return <PageBuilder pagebuilder={cmsPage?.pagebuilder} />;
}

export const getStaticProps = withStaticProps<ErrorPageProps>(async () => {
  const homePageQuery = apolloClient.query({
    query: gql(`
      query ErrorPage($identifier: String!) {
        cmsPage(identifier: $identifier) {
          identifier
          content_heading
          pagebuilder {
            html
            components
          }
        }
      }
    `),
    variables: {
      identifier: 'no-route',
    },
  });

  return {
    props: {
      cmsPage: (await homePageQuery).data.cmsPage,
    },
    revalidate: serverEnv.ISG_REVALIDATE_PAGE_404,
  };
});
